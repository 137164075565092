<template>
  <main class="homepage-main" v-if="homePageContent">
    <section
      class="homepage-landing js-homepage-landing"
      ref="homepageLanding"
    >
      <div class="homepage-landing__arcs">
        <svg id="arcsa" viewBox="0 0 12308 6730" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>
              <path d="M12308,0 L12308,6730 L6444,6730 L6444,6443.07496 C6444,6277.90053 6314.61018,6144 6155,6144 C5995.38982,6144 5866,6277.90053 5866,6443.07496 L5866,6443.07496 L5866,6730 L0,6730 L0,0 L12308,0 Z" id="path-1"></path>
              <path d="M12308,0 L12308,6730 L6474,6730 L6474,6423 C6474,6246.269 6330.731,6103 6154,6103 C5977.269,6103 5834,6246.269 5834,6423 L5834,6423 L5834,6730 L0,6730 L0,0 L12308,0 Z" id="path-3"></path>
              <path d="M12308,0 L12308,6730 L6522,6730 L6522,6413 C6522,6209.759 6357.241,6045 6154,6045 L6154,6045 L6147.91444,6045.0493 C5947.4803,6048.29903 5786,6211.79141 5786,6413 L5786,6413 L5786,6730 L0,6730 L0,0 L12308,0 Z" id="path-5"></path>
              <path d="M12308,0 L12308,6730 L6582,6730 L6582,6401 C6582,6164.622 6390.378,5973 6154,5973 C5917.622,5973 5726,6164.622 5726,6401 L5726,6401 L5726,6730 L0,6730 L0,0 L12308,0 Z" id="path-7"></path>
              <path d="M12308,0 L12308,6730 L6668,6730 L6668,6392 C6668,6108.126 6437.874,5878 6154,5878 C5870.126,5878 5640,6108.126 5640,6392 L5640,6392 L5640,6730 L0,6730 L0,0 L12308,0 Z" id="path-9"></path>
          </defs>
          <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g class="arc" ref="arc0" id="arc-0">
                  <mask id="mask-2" fill="white">
                      <use xlink:href="#path-1"></use>
                  </mask>
                  <use id="Combined-Shape" fill="#AE9E94" xlink:href="#path-1"></use>
              </g>
              <g class="arc" ref="arc1" id="arc-1">
                  <mask id="mask-4" fill="white">
                      <use xlink:href="#path-3"></use>
                  </mask>
                  <use id="Combined-Shape" fill="#B2A297" xlink:href="#path-3"></use>
              </g>
              <g class="arc" ref="arc2" id="arc-2">
                  <mask id="mask-6" fill="white">
                      <use xlink:href="#path-5"></use>
                  </mask>
                  <use id="Combined-Shape" fill="#B7A79D" xlink:href="#path-5"></use>
              </g>
              <g class="arc" ref="arc3" id="arc-3">
                  <mask id="mask-8" fill="white">
                      <use xlink:href="#path-7"></use>
                  </mask>
                  <use id="Combined-Shape" fill="#BEB2A6" xlink:href="#path-7"></use>
              </g>
              <g class="arc" ref="arc4" id="arc-4">
                  <mask id="mask-10" fill="white">
                      <use xlink:href="#path-9"></use>
                  </mask>
                  <use id="Combined-Shape" fill="#C9BEB3" xlink:href="#path-9"></use>
              </g>
          </g>
        </svg>
      </div>
      <div class="homepage-landing__arcs-mobile">
        <svg id="arcsMobile" viewBox="0 0 476 1314" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
            <title>Artboard</title>
            <desc>Created with Sketch.</desc>
            <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="arcs-mobile" transform="translate(-224.000000, -347.000000)" fill-rule="nonzero">
                    <path d="M757,625 L757,1724 L660.319328,1724 L660.319328,1194.94363 C660.319328,1085.45808 571.529286,996.702368 462,996.702368 C352.47121,996.702368 263.680672,1085.45795 263.680672,1194.94363 L263.680672,1724 L167,1724 L167,625 L757,625 Z" id="Combined-Shape" fill="#AE9E94"></path>
                    <path d="M781,561 L781,1755 L675.777563,1755 L675.777563,1180.17222 C675.777563,1061.21451 579.341849,964.780083 460.381044,964.780083 C341.420778,964.780083 244.984526,1061.21438 244.984526,1180.17222 L244.984526,1755 L141,1755 L141,561 L781,561 Z" id="Combined-Shape" fill="#B2A297"></path>
                    <path d="M815,479 L815,1798 L698.611208,1798 L698.611208,1162.85325 C698.611208,1031.41283 592.176382,924.859155 460.880911,924.859155 C329.586033,924.859155 223.150613,1031.41268 223.150613,1162.85325 L223.150613,1798 L108,1798 L108,479 L815,479 Z" id="Combined-Shape" fill="#B7A79D"></path>
                    <path d="M865,364 L865,1871 L731.159509,1871 L731.159509,1144.98183 C731.159509,994.736026 609.650886,872.937551 459.760736,872.937551 C309.871265,872.937551 188.361963,994.735856 188.361963,1144.98183 L188.361963,1871 L57,1871 L57,364 L865,364 Z" id="Combined-Shape" fill="#BEB2A6"></path>
                    <path d="M924,217 L924,1942 L770.412869,1942 L770.412869,1110.56279 C770.412869,938.501044 631.500763,799.017229 460.142091,799.017229 C288.784195,799.017229 149.871314,938.500849 149.871314,1110.56279 L149.871314,1942 L0,1942 L0,217 L924,217 Z" id="Combined-Shape" fill="#C9BEB3"></path>
                </g>
            </g>
        </svg>
      </div>
      <div class="homepage-landing__headline js-hero-headline" ref="landingHead">
        <h1
          class=" "
        >
          {{ homePageContent.fields.landingTextHeadline }}
        </h1>
        <p class="homepage-landing__headline-subline">{{ homePageContent.fields.landingTextSubline }}</p>
      </div>

      <img
        :src="getSizedImage(homePageContent.fields.landingImage.fields.file.url, 2000, 90)"
        ref="landingImage"
        alt=""
      >
      <div
        ref="heroText"
        class="homepage-hero__text"
      >
        <router-link
          :to="'/project/' + handleize(homePageContent.fields.landingProjectTitle)"
        >
          <span>{{ homePageContent.fields.landingProjectTitle }}</span>
          <span>See project</span>
        </router-link>
      </div>
    </section>
    <div
      class="homepage-landing__arrow"
      ref="landingArrow"
    >
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="49.5" stroke="white"/>
        <path d="M50 42V58.5M50 58.5L55 53.5M50 58.5L45 53.5" stroke="white" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <section
      class="homepage-projects"
      ref="projectSection"
    >
      <div class="container">
        <h2>{{ homePageContent.fields.projectHeadline }}</h2>
        <p data-splitting data-text-reveal>{{ homePageContent.fields.projectSubline }}</p>
        <div class="homepage-projects__list">
          <template
            v-for="(cat, index) in categories"
            :key="`cat-${index}`"
          >
            <router-link
              :to="'/projects?cat=' + handleize(cat)"
              class="homepage-projects__list-item js-project-cat"
              @mouseenter="(e) => hoverCat(e, cat, index)"
            >
              <div data-splitting data-text-reveal class="homepage-projects__list-title">
                {{ cat }}
              </div>
              <div class="homepage-projects__list-subtitle">
                See our {{ cat }} projects
              </div>
            </router-link>
          </template>
          <router-link to="/projects" class="homepage-projects__list-btn button button--dark-gray">
            See all projects
          </router-link>
        </div>
        <div ref="projectsImages" class="homepage-projects__list-images">
          <template
            v-for="(cat, index) in categories"
            :key="`cat-${index}`"
          >
            <div 
              :data-cat="handleize(cat)"
              :class="index === 0 ? 'is-hovered' : ''"
              class="homepage-projects__list-image js-project-image"
            >
              <div class="homepage-projects__list-imagewrap">
                <img alt="" :src="getSizedImage(findImage(cat), 1200, 75)">
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
    <section ref="aboutUsSection" class="js-sticky homepage-about">
      <div class="homepage-about__background"></div>
      <div ref="abountContent" class="homepage-about__content">
        <h2 data-splitting data-text-reveal>{{ homePageContent.fields.aboutHeadline }}</h2>
        <div class="homepage-about__body" data-splitting data-text-reveal v-html="aboutUsContent">
        </div>
        <router-link 
          to="/about"
          class="homepage-about__btn button"
        >
          Learn about us
        </router-link>
      </div>
    </section>
  </main>
  <Companies/>
</template>

<script setup>

import {
  onBeforeUnmount, onMounted, inject, ref, provide, onUpdated,
} from 'vue';
import { isInView, isOutOfView } from 'isinview';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import { Renderer, Vec2, Vec4, Flowmap, Geometry, Texture, Program, Mesh } from 'ogl';
import anime from 'animejs/lib/anime.es.js';
import Companies from '@/components/Companies.vue'

const content = inject('content');
const MarkdownIt = require('markdown-it');
const md = new MarkdownIt();
const homePageContent = ref(null);
const hero = ref(null);
const landingImage = ref(null);
const homepageLanding = ref(null);
const heroText = ref(null);
let aboutUsContent = ref(null);
const categories = ref([]);
const projects = ref([]);
const aboutUsSection = ref(null);
const projectSection = ref(null);
const arc0 = ref(null);
const arc1 = ref(null);
const arc2 = ref(null);
const arc3 = ref(null);
const arc4 = ref(null);
const landingHead = ref(null);
const landingArrow = ref(null);
const abountContent = ref(null);
const abountContentCopy = ref(null);
const projectImages = ref(null);
const projectsImages = ref(null);
const isMobile = window.matchMedia("(max-width: 767px)").matches;
const landingBreakpoint = isMobile ? 100 : 1200;

const handleize = (str) =>  {
  let result;
  if (str) result = str.toLowerCase().replace(/[^\w\u00C0-\u024f]+/g, "-").replace(/^-+|-+$/g, "");
  return result
};

homePageContent.value = content.items.find(item => item.sys.contentType.sys.id === 'homePage');
projectImages.value = homePageContent.value?.fields?.projectImage;

aboutUsContent.value = md.render(homePageContent.value?.fields.aboutUsText);
projects.value = content.items.filter(item => item.sys.contentType.sys.id === 'project');

projects.value.forEach((project) => {
  const filterDowncase = project.fields.group.toLowerCase().trim();
  if (categories.value.indexOf(filterDowncase) === -1) {
    categories.value.push(filterDowncase.trim());
  }
});

if (categories.value) {
  const sortArray = projectImages.value.map((item) => handleize(item.fields.title));
  // sort categorries 
  categories.value.sort((a, b) => {
    const aIndex = sortArray.indexOf(a);
    const bIndex = sortArray.indexOf(b);
    return aIndex - bIndex;
  });
}

const getSizedImage = (url, width, quality) => {
  const obj = {};
  if (width) obj.w = width;
  if (quality) obj.q = quality;
  const params = new URLSearchParams(obj).toString();

  if (params.length) url += `?${params}&fm=avif`; 
    url = url.replace('downloads.ctfassets.net', 'images.ctfassets.net');
  return url;
}

const getRandomNumber = (min, max) => {
  return Math.random() * (max - min) + min;
}

const hoverCat = (e, cat, index) => {
  const $target = e.target;
  const $image = document.querySelector(`.js-project-image[data-cat="${handleize(cat)}"]`);
  const $images = document.querySelectorAll(`.js-project-image`);
  const $cats = document.querySelectorAll(`.js-project-cat`);
  $cats.forEach($cat => {
    $cat.classList.remove('is-active');
  });

  $target.classList.add('is-active');
  $images.forEach($image => {
    $image.classList.remove('is-hovered');
    $image.parentNode.classList.remove('is-child-hovered');
  });
  $image.classList.add('is-hovered');
  $image.parentNode.classList.add('is-child-hovered');
};

const mouseleaveCat = (e, cat) => {
  const $images = document.querySelectorAll(`.js-project-image`);
  $images.forEach($image => {
    $image.classList.remove('is-hovered');
    $image.parentNode.classList.remove('is-child-hovered');
  });
};

const findImage = (cat) => {
  const image = projectImages.value.find(item => handleize(item.fields.title) === handleize(cat));
  return image?.fields.file.url;
}

function addHoverEffect() {
   // Main Code
  const imgSize = [2000, 1164];
  const vertex = `
        attribute vec2 uv;
        attribute vec2 position;
        varying vec2 vUv;
        void main() {
            vUv = uv;
            gl_Position = vec4(position, 0, 1);
        }
    `;
  const fragment = `
        precision highp float;
        precision highp int;
        uniform sampler2D tWater;
        uniform sampler2D tFlow;
        uniform float uTime;
        varying vec2 vUv;
        uniform vec4 res;

        void main() {

            // R and G values are velocity in the x and y direction
            // B value is the velocity length
            vec3 flow = texture2D(tFlow, vUv).rgb;

            vec2 uv = .5 * gl_FragCoord.xy / res.xy ;
            vec2 myUV = (uv - vec2(0.5))*res.zw + vec2(0.5);
            myUV -= flow.xy * (0.15 * 0.7);

            vec3 tex = texture2D(tWater, myUV).rgb;

            gl_FragColor = vec4(tex.r, tex.g, tex.b, 1.0);
        }
    `;
      {
        const renderer = new Renderer({ dpr: 2 });
        const gl = renderer.gl;
        const $arcs = document.querySelector('.homepage-landing__arcs');
        $arcs.appendChild(gl.canvas);

        // Variable inputs to control flowmap
        let aspect = 1;
        const mouse = new Vec2(-1);
        const velocity = new Vec2();
        function resize() {
          let a1, a2;
          var imageAspect = imgSize[1] / imgSize[0];
          if (window.innerHeight / window.innerWidth < imageAspect) {
            a1 = 1;
            a2 = window.innerHeight / window.innerWidth / imageAspect;
          } else {
            a1 = (window.innerWidth / window.innerHeight) * imageAspect;
            a2 = 1;
          }
          mesh.program.uniforms.res.value = new Vec4(
            window.innerWidth,
            window.innerHeight,
            a1,
            a2
          );

          renderer.setSize(window.innerWidth, window.innerHeight);
          aspect = window.innerWidth / window.innerHeight;
        }
        const flowmap = new Flowmap(gl);
        // Triangle that includes -1 to 1 range for 'position', and 0 to 1 range for 'uv'.
        const geometry = new Geometry(gl, {
          position: {
            size: 2,
            data: new Float32Array([-1, -1, 3, -1, -1, 3])
          },
          uv: { size: 2, data: new Float32Array([0, 0, 2, 0, 0, 2]) }
        });
        const texture = new Texture(gl, {
          minFilter: gl.LINEAR,
          magFilter: gl.LINEAR
        });
        const img = new Image();
        img.onload = () => (texture.image = img);
        img.crossOrigin = "Anonymous";
        const imageUrl = getSizedImage(homePageContent.value?.fields?.landingImage.fields.file.url, 2000, 90)
        img.src = imageUrl;

        let a1, a2;
        var imageAspect = imgSize[1] / imgSize[0];
        if (window.innerHeight / window.innerWidth < imageAspect) {
          a1 = 1;
          a2 = window.innerHeight / window.innerWidth / imageAspect;
        } else {
          a1 = (window.innerWidth / window.innerHeight) * imageAspect;
          a2 = 1;
        }

        const program = new Program(gl, {
          vertex,
          fragment,
          uniforms: {
            uTime: { value: 0 },
            tWater: { value: texture },
            res: {
              value: new Vec4(window.innerWidth, window.innerHeight, a1, a2)
            },
            img: { value: new Vec2(imgSize[0], imgSize[1]) },
            // Note that the uniform is applied without using an object and value property
            // This is because the class alternates this texture between two render targets
            // and updates the value property after each render.
            tFlow: flowmap.uniform
          }
        });
        const mesh = new Mesh(gl, { geometry, program });

        window.addEventListener("resize", resize, false);
        resize();

        // Create handlers to get mouse position and velocity
        const isTouchCapable = "ontouchstart" in window;
        if (isTouchCapable) {
          window.addEventListener("touchstart", updateMouse, false);
          window.addEventListener("touchmove", updateMouse, { passive: false });
        } else {
          window.addEventListener("mousemove", updateMouse, false);
        }
        let lastTime;
        const lastMouse = new Vec2();
        function updateMouse(e) {
          e.preventDefault();
          if (e.changedTouches && e.changedTouches.length) {
            e.x = e.changedTouches[0].pageX;
            e.y = e.changedTouches[0].pageY;
          }
          if (e.x === undefined) {
            e.x = e.pageX;
            e.y = e.pageY;
          }
          // Get mouse value in 0 to 1 range, with y flipped
          mouse.set(e.x / gl.renderer.width, 1.0 - e.y / gl.renderer.height);
          // Calculate velocity
          if (!lastTime) {
            // First frame
            lastTime = performance.now();
            lastMouse.set(e.x, e.y);
          }

          const deltaX = e.x - lastMouse.x;
          const deltaY = e.y - lastMouse.y;

          lastMouse.set(e.x, e.y);

          let time = performance.now();

          // Avoid dividing by 0
          let delta = Math.max(10.4, time - lastTime);
          lastTime = time;
          velocity.x = deltaX / delta;
          velocity.y = deltaY / delta;
          // Flag update to prevent hanging velocity values when not moving
          velocity.needsUpdate = true;
        }
        requestAnimationFrame(update);


        function update(t) {
          requestAnimationFrame(update);
          // Reset velocity when mouse not moving
          if (!velocity.needsUpdate) {
            mouse.set(-1);
            velocity.set(0);
          }
          velocity.needsUpdate = false;
          // Update flowmap inputs
          flowmap.aspect = aspect;
          flowmap.mouse.copy(mouse);
          // Ease velocity input, slower when fading out
          flowmap.velocity.lerp(velocity, velocity.len ? 0.15 : 0.1);
          if (scrollY > landingBreakpoint) flowmap.update();
          program.uniforms.uTime.value = t * 0.01;
          renderer.render({ scene: mesh });
        }
      }

}

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
  window.removeEventListener('resize', refreshPage);
  document.body.classList.remove('homepage');
  document.body.classList.remove('project-is-in-view');
  document.body.classList.remove('footer-is-in-view');
  document.body.classList.remove('about-is-in-view');
  document.body.classList.remove('clients-is-in-view');
  document.body.classList.remove('landing-is-in-view');
  document.body.classList.remove('is-loaded');
});

onMounted(() => {
  document.body.classList.add('is-loaded');
  document.body.classList.remove('initial-load');
  window.scrollTo({ top: 0 });
  window.addEventListener('scroll', handleScroll);
  document.body.classList.add('homepage');
  document.body.classList.add('landing-is-in-view');

  const options = {
    once: false,
    threshold: 0.2,
  }

  setTimeout(() => {
    isInView(aboutUsSection.value, (target) => {
      document.body.classList.add('about-is-in-view');
    }, options)

    isOutOfView(aboutUsSection.value, (target) => {
      document.body.classList.remove('about-is-in-view');
    }, options);
    

    isInView(document.querySelectorAll('[data-heading-anim-in-view]'), (target) => {
      const $letters = target.querySelectorAll('.char');
      anime.timeline({loop: false})
        .add({
          targets: $letters,
          translateY: [60,0],
          translateZ: 0,
          opacity: [0,1],
          easing: "easeOutExpo",
          duration: 800,
          delay: (el, i) => 150 + 30 * i
        })
    }, options);

    isOutOfView(document.querySelectorAll('[data-heading-anim-in-view]'), (target) => {
      const $letters = target.querySelectorAll('.char');
      anime.timeline({loop: false})
        .add({
          targets: $letters,
          opacity: [1,0],
          easing: "easeInExpo",
          duration: 200,
          delay: (el, i) => 100 + 30 * i
        });
    }, options);
  }, 100);

  setTimeout(() => {
    isInView(projectSection.value, (target) => {
      document.body.classList.add('project-is-in-view');
    }, options)

    isOutOfView(projectSection.value, (target) => {
      document.body.classList.remove('project-is-in-view');
    }, options)
  }, 100);

  setTimeout(() => {
    isInView(document.querySelector('.clients'), (target) => {
      document.body.classList.add('clients-is-in-view');
    }, options)

    isOutOfView(document.querySelector('.clients'), (target) => {
      document.body.classList.remove('clients-is-in-view');
    }, options)
  }, 100);

  Splitting();

  if (!isMobile) {
    gsap.fromTo('#arcsa',
      {scale: 7.6 },
      { 
        scale: 30,
        transformOrigin: 'center bottom', 
        scrollTrigger: {
          start: 400,
          end: "+=2000",
          pin:true,
          scrub: true
      }}
    );
  } else {
    gsap.fromTo('#arcsMobile',
      {scale: 1,
      opacity: 1 },
      { 
        opacity: 0,
        scale: 20,
        transformOrigin: 'center bottom', 
        scrollTrigger: {
          start: 0,
          end: "+=10000",
          pin: true,
          scrub: true
      }}
    );
  }

  const landingScale = isMobile ? 0.89 : 0.9;
  const landingStart = isMobile ? 700 : 1700;
  const landingEnd = isMobile ? '+=250' : "+=1000";
  gsap.fromTo(homepageLanding.value,
    {scale: 1 },
    { 
      scale: landingScale,
      transformOrigin: 'center center', 
      scrollTrigger: {
        start: landingStart,
        end: landingEnd,
        pin:true,
        scrub: true,
    }}
  );

  const landingStartMove = isMobile ? 1100 : 3200;
  const landingEndMove = isMobile ? "+=1000" : "+=1500";
  gsap.fromTo(homepageLanding.value,
    {yPercent: 0 },
    { 
      yPercent: -100,
      transformOrigin: 'center center', 
      scrollTrigger: {
        start: landingStartMove,
        end: landingEndMove,
        pin:true,
        scrub: true,
    }}
  );

  gsap.fromTo(projectsImages.value,
    {yPercent: 20 },
    { 
      yPercent: -50,
      transformOrigin: 'center center', 
      scrollTrigger: {
        start: 4000,
        end: "+=2000",
        pin:true,
        scrub: true,
        marker: true
    }}
  );

  if (!isMobile) {
    gsap.fromTo(aboutUsSection.value,
      {yPercent: 100 },
      { 
        yPercent: 0,
        transformOrigin: 'center center', 
        scrollTrigger: {
          start: 4350,
          end: "+=1500",
          pin:true,
          scrub: true,
      }}
    );
  }


  if (!isMobile) {
    gsap.fromTo('.homepage-about__background',
      {opacity: 0 },
      { 
        opacity: 1,
        scrollTrigger: {
          start: `.homepage-about__background+=2000`,
          end: "+=300",
          pin: true,
          scrub: true
      }}
    );
    gsap.fromTo('.homepage-about__background',
      {scale: 0 },
      { 
        scale: 200,
        scrollTrigger: {
          start: `.homepage-about__background+=2000`,
          end: "+=4000",
          pin: true,
          scrub: true
      }}
    );
  }

  if (!isMobile) {
    gsap.fromTo(abountContent.value,
      {yPercent: 0 },
      { 
        yPercent: -150,
        scrollTrigger: {
          start: `.homepage-about__background+=2700`,
          end: "+=1500",
          pin: true,
          scrub: true
      }}
    );
  }

  const title = document.querySelectorAll('[data-text-reveal]');
  title.forEach(title => {
    gsap.fromTo(title.querySelectorAll('.word'), 
      {
        'will-change': 'opacity',
        opacity: 0
      },
      {
        ease: 'none',
        opacity: 1,
        stagger: 0.05,
        scrollTrigger: {
          trigger: title,
          start: 'top bottom',
          end: 'center top+=87%',
          scrub: true,
        }
      });
    });

  window.addEventListener('resize', refreshPage, true);

  if (!isMobile) {
    addHoverEffect();
  }
});

const refreshPage = () => {
  if (!isMobile) location.reload();
};

const handleScroll = () => {
  if (window.scrollY > landingBreakpoint) {
    document.body.classList.remove('landing-is-in-view');
  } else {
    document.body.classList.add('landing-is-in-view');
  }

  const landingDivider = isMobile ? 50 : 200;
  landingHead.value.style.transform = `translate(-50%, 0)`;
  landingHead.value.style.opacity = `${1 - (window.scrollY / landingDivider)}`;
}

provide('homePageContent', homePageContent);
provide('aboutUsContent', homePageContent);
provide('projects', projects);
provide('findImage', findImage);
provide('getSizedImage', getSizedImage);
provide('hoverCat', hoverCat);
</script>
