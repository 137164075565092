<template>
  <div class="clients" ref="clientsSection">
    <h2 data-splitting data-text-reveal>Our Clients</h2>

    <p data-splitting data-text-reveal>We’ve had the honor of working with incredible teams who value beautiful and functional design as much as we do</p>
    <ul class="clients__list" ref="list">
      <template
        v-for="(el, index) in clients"
        :key="`el-${index}`"
      >
        <li class="clients__list-client" >
          <img :src="getSizedImage(el.fields.file.url, 440, 75)" alt=""/>
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup>
  import {
    inject, provide, ref, onMounted, onBeforeUnmount
  } from 'vue';
  import { isInView, isOutOfView } from 'isinview';
  import "splitting/dist/splitting.css";
  import "splitting/dist/splitting-cells.css";
  import Splitting from "splitting";

  const content = inject('content');
  const companiesContent = content.items.find(item => item.sys.contentType.sys.id === 'footer');
  const clients = ref([]);
  const list = ref(null);
  clients.value = [
    ...companiesContent.fields.clientLogos,
  ];

  const getSizedImage = (url, width, quality) => {
    const obj = {};
    if (width) obj.w = width;
    if (quality) obj.q = quality;
    const params = new URLSearchParams(obj).toString();

    if (params.length) url += `?${params}&fm=avif`; 
    url = url.replace('downloads.ctfassets.net', 'images.ctfassets.net');
    return url;
  }

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
  });

  const handleScroll = () => {
    const isMobile = window.matchMedia("(max-width: 767px)").matches;
    const fromTop = list.value.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;
    const isInView = fromTop - windowHeight <= 0;
    const isOutOfView = fromTop - windowHeight > 0;
    const percentage = (fromTop / windowHeight) * 100;
    const listWidth = list.value.scrollWidth;
    const currentScroll = list.value.scrollLeft;
    const scrollValue = currentScroll + (listWidth / 100) * (100 - percentage);
  }

  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
    setTimeout(() => {

      Splitting();
    }, 100);
  });

  provide('companiesContent', companiesContent);
  provide('clients', clients);
</script>