import App from './App.vue'
import Default from '@/layouts/default/Default.vue'
import { createApp, provide } from 'vue'
import { createPinia } from 'pinia'
import { router } from './router'
import { createClient } from 'contentful'

const contentful = require('contentful')

const client = createClient({
  space: 'aa85oagcqmbh',
  environment: 'master', // defaults to 'master' if not set
  accessToken: 'cRIDEiAnYrtU1Hb5hr-aUC6fZjqYxKBh1FhSJcKzbkQ'
})

client.getEntries({
    limit: 1000,
  }).then((response) => {
    const app = createApp(App)
    app.component('LayoutDefault', Default)
    app.use(createPinia())
    app.use(router)
    app.provide('content', response);
    app.mount('#app')
  })
  .catch(console.error)

