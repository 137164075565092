<template>
  <footer class="footer">
    <div class="footer-bottom">
      <div class="footer-arcs">
        <div class="footer-arcs__arc">
          <svg width="958" height="692" viewBox="0 0 958 692" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M0 479C0 214.456 214.456 0 479 0C743.544 0 958 214.456 958 479V692H0V479Z" fill="#C9BEB3"/>
          </svg>                              
        </div>
        <div class="footer-arcs__arc">
          <svg width="812" height="615" viewBox="0 0 812 615" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M0 406C0 181.772 181.772 0 406 0C630.228 0 812 181.772 812 406V615H0V406Z" fill="#C9BEB3"/>
          </svg>                
        </div>
        <div class="footer-arcs__arc">
          <svg width="691" height="541" viewBox="0 0 691 541" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M0 345.5C0 154.686 154.686 0 345.5 0C536.314 0 691 154.686 691 345.5V541H0V345.5Z" fill="#C9BEB3"/>
          </svg>
        </div>
        <div class="footer-arcs__arc">
          <svg width="576" height="480" viewBox="0 0 576 480" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 288C0 128.942 128.942 0 288 0C447.058 0 576 128.942 576 288V480H0V288Z" fill="#C9BEB3"/>
          </svg>                
        </div>
      </div>

      <div class="footer-bottom__content">
        <h2>Get in touch</h2>
        <router-link to="/contact" class="button button--white">Contact</router-link>
        <nav class="footer-bottom__nav">
          <router-link to="/" @click="(e) => scrollToTop(e)" class="footer-bottom__nav-link">Home</router-link>
          <router-link to="/about" @click="(e) => scrollToTop(e)" class="footer-bottom__nav-link">About</router-link>
          <router-link to="/projects" @click="(e) => scrollToTop(e)" class="footer-bottom__nav-link">Projects</router-link>
          <router-link to="/contact" @click="(e) => scrollToTop(e)" class="footer-bottom__nav-link">Contact</router-link>
        </nav>
      </div>
    </div>
  </footer>
  <div class="copyright">
    <span class="">© 2023 Design By Mahsa. All rights reserved.</span>
  </div>
</template>

<script setup>
  import {
    onMounted, onUpdated,
  } from 'vue';

  import { isInView, isOutOfView } from 'isinview';

  const scrollToTop = (e) => {
    const $target = e.target;
    const isActive = $target.classList.contains('active');
    if (isActive) window.scrollTo({top: 0, behavior: 'smooth'});
  };

  onMounted(() => {
    const options = {
      once: false,
      threshold: 0.5,
    }
    setTimeout(() => {
      isInView(document.querySelector('.footer'), (target) => {
        document.body.classList.add('footer-is-in-view');
      }, options)

      isOutOfView(document.querySelector('.footer'), (target) => {
        document.body.classList.remove('footer-is-in-view');
      }, options)
    }, 200);
  });

</script>
