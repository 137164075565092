<template>
  <header class="header">
    <router-link to="/" class="header__logo">
      <svg viewBox="0 0 100 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.38496 0.0090332H0V26.0742H7.26214C17.2448 26.0742 23.4471 21.0274 23.4471 12.9082C23.4471 4.78896 17.3927 0.0090332 8.38496 0.0090332ZM5.35304 1.71658H6.48215C9.74052 1.76208 12.5523 2.8782 14.6186 4.94364C16.924 7.24868 18.1444 10.6274 18.0563 14.455C17.9085 21.0243 14.3356 24.3697 7.43513 24.4H5.35304V1.71355V1.71658Z" fill="white"/>
        <path d="M44.7242 11.7223C45.3438 11.4433 45.8753 11.1309 46.3503 10.773C47.9134 9.62348 48.8098 7.99479 48.8098 6.2933C48.7783 3.25429 46.1993 0.0090332 39.0535 0.0090332H28.6997V26.0742H40.0348C43.1265 26.0742 45.5923 25.5404 47.3662 24.4849C49.5017 23.2141 50.7 21.1487 50.7409 18.6738C50.8132 15.3254 48.4953 12.711 44.7242 11.7253V11.7223ZM43.0856 4.82232C43.6832 5.61392 43.809 6.5602 43.809 7.21229C43.809 8.25259 43.4442 9.25649 42.834 9.90555C42.0131 10.7881 40.6701 11.2522 38.9497 11.2522H34.0559V1.68019H36.4902C40.3713 1.68019 42.205 3.36954 43.0604 4.78593L43.0856 4.82232ZM34.0559 12.9597H37.6854C42.7679 12.9597 45.4256 16.3566 45.4256 19.7111C45.4256 20.7301 45.1771 22.1526 43.9977 23.1474L43.9883 23.1565C43.0667 23.9784 41.5602 24.397 39.5127 24.397H34.0559V12.9597Z" fill="white"/>
        <path d="M82.6511 0L72.6967 21.082C72.6967 21.082 62.7015 1.65296 62.2549 1.0403C61.8082 0.427646 61.0345 0 59.1254 0H56.04V26.0712H57.7384V3.90947L69.1207 25.8286L69.2434 26.0652H72.335L82.4907 4.48573V26.0652H87.8784V0H82.648H82.6511Z" fill="white"/>
        <path d="M96.5686 26.0713C98.4636 26.0713 99.9999 24.5898 99.9999 22.7623C99.9999 20.9348 98.4636 19.4534 96.5686 19.4534C94.6735 19.4534 93.1372 20.9348 93.1372 22.7623C93.1372 24.5898 94.6735 26.0713 96.5686 26.0713Z" fill="#C8BEB3"/>
      </svg> 
    </router-link>
    <button class="header__button" @click="toggleNavbar">
      <span class="header__button-line"></span>
      <span class="header__button-line"></span>
    </button>
  </header>
  <nav class="nav">
    <router-link to="/about" class="nav__item" @click="toggleNavbar">About</router-link>
    <router-link to="/projects" class="nav__item" @click="toggleNavbar">Projects</router-link>
    <router-link to="/contact" class="nav__item" @click="toggleNavbar">Contact</router-link>
  </nav>

</template>

<script setup>
  const toggleNavbar = () => {
    document.body.classList.toggle('nav-is-exposed');
  }
</script>
