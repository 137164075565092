
<template>
  <Nav />
  <router-view v-slot="{ Component, route }">
    <Transition name="slide-fade" mode="out-in">
      <div class="main" :key="route.name">  
        <component :is="Component"></component>
      </div>
    </Transition>
  </router-view>
  <Footer v-if="showFooter"/>
  <div class="transition">

    <div class="transition__text">
      <svg width="192" height="64" viewBox="0 0 192 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.0559 0.017334H0V49.928H13.9059C33.0211 49.928 44.8974 40.2641 44.8974 24.7171C44.8974 9.17014 33.3042 0.017334 16.0559 0.017334ZM10.2502 3.28702H12.4123C18.6516 3.37414 24.0356 5.51134 27.9924 9.46633C32.4069 13.8801 34.7436 20.3498 34.5749 27.679C34.2919 40.2583 27.4504 46.6641 14.2371 46.7222H10.2502V3.28122V3.28702Z" fill="white"/>
      <path d="M85.6395 22.4464C86.8259 21.9121 87.8437 21.3139 88.7531 20.6286C91.7463 18.4275 93.4627 15.3088 93.4627 12.0507C93.4025 6.23149 88.464 0.017334 74.781 0.017334H54.9551V49.928H76.66C82.5801 49.928 87.3017 48.9059 90.6984 46.8848C94.7876 44.4514 97.0822 40.4964 97.1605 35.7574C97.299 29.3458 92.8604 24.3396 85.6395 22.4522V22.4464ZM82.5018 9.23403C83.6461 10.7498 83.887 12.5618 83.887 13.8104C83.887 15.8024 83.1884 17.7248 82.02 18.9676C80.4482 20.6576 77.8766 21.5462 74.5823 21.5462H65.2113V3.21733H69.8727C77.3044 3.21733 80.8155 6.45218 82.4536 9.16433L82.5018 9.23403ZM65.2113 24.8159H72.1612C81.8935 24.8159 86.9825 31.3204 86.9825 37.7436C86.9825 39.695 86.5067 42.4188 84.2483 44.3237L84.2303 44.3411C82.4657 45.9149 79.5809 46.7164 75.6603 46.7164H65.2113V24.8159Z" fill="white"/>
      <path d="M158.264 0L139.203 40.3688C139.203 40.3688 120.063 3.16515 119.208 1.99201C118.353 0.818875 116.871 0 113.216 0H107.308V49.9223H110.56V7.48602L132.355 49.4577L132.59 49.9107H138.51L157.957 8.58947V49.9107H168.273V0H158.258H158.264Z" fill="white"/>
      <path d="M184.913 49.9222C188.542 49.9222 191.484 47.0855 191.484 43.5861C191.484 40.0868 188.542 37.25 184.913 37.25C181.284 37.25 178.343 40.0868 178.343 43.5861C178.343 47.0855 181.284 49.9222 184.913 49.9222Z" fill="#C8BEB3"/>
      <path d="M1.87299 63.9361H0V59.5862H1.84287C3.7279 59.5862 4.05312 60.7651 4.05312 61.764C4.05312 62.763 3.73393 63.9419 1.86696 63.9419L1.87299 63.9361ZM1.78867 60.2947H0.728718L0.80701 60.3702V63.1521L0.728718 63.2276H1.81276C3.03532 63.2276 3.22804 62.5829 3.22804 61.7582C3.22804 60.9335 3.0293 60.2947 1.78265 60.2947H1.78867Z" fill="white"/>
      <path d="M15.9108 63.9361H12.3154V59.5862H15.9108V60.2947H13.0502L13.1285 60.3702V61.3343L13.0502 61.4098H15.6458V62.1183H13.0502L13.1285 62.1938V63.1579L13.0502 63.2334H15.9108V63.9419V63.9361Z" fill="white"/>
      <path d="M26.1438 63.9884C25.397 63.9884 24.6201 63.7735 24.042 63.2218L24.6202 62.67C25.1742 63.1347 25.5837 63.2798 26.1197 63.2798C26.9448 63.2798 27.258 63.0011 27.258 62.7281C27.258 62.5423 27.0773 62.31 26.4871 62.1706C26.3847 62.1416 25.7524 61.9906 25.65 61.9673C24.837 61.7582 24.1805 61.4737 24.1805 60.771C24.1805 60.1089 24.8129 59.5339 26.0836 59.5339C26.8424 59.5339 27.4688 59.7779 27.9747 60.2657L27.3965 60.8232C27.0532 60.4399 26.6437 60.2425 26.0957 60.2425C25.2646 60.2425 25.0116 60.5328 25.0116 60.7477C25.0116 60.951 25.2164 61.0904 26.0053 61.2878C26.1017 61.3169 26.6256 61.4446 26.728 61.4679C27.6555 61.6886 28.0891 62.1416 28.0891 62.6933C28.0891 63.3612 27.529 63.9884 26.1499 63.9884H26.1438Z" fill="white"/>
      <path d="M37.1527 63.9361H36.3457V59.5862H37.1527V63.9361Z" fill="white"/>
      <path d="M49.8477 63.942H49.1551V63.4541C48.8962 63.7677 48.3602 63.9942 47.782 63.9942C46.2945 63.9942 45.4453 63.1405 45.4453 61.8106C45.4453 60.4806 46.3125 59.5398 47.8121 59.5398C48.4505 59.5398 49.1371 59.7024 49.655 60.2077L49.0829 60.7652C48.7276 60.3645 48.2819 60.2483 47.8182 60.2483C46.7642 60.2483 46.2704 60.8291 46.2704 61.8106C46.2704 62.7921 46.7462 63.2857 47.788 63.2857C48.5348 63.2857 49.0467 62.8153 49.0889 62.1881L49.1672 62.1126H47.6254V61.404H49.8477V63.9478V63.942Z" fill="white"/>
      <path d="M62.2904 63.936H61.5978L59.4057 61.2936L59.1286 60.8232H59.1166V63.936H58.3096V59.5861H59.0022L61.1943 62.2228L61.4714 62.699H61.4834V59.5803H62.2904V63.9302V63.936Z" fill="white"/>
      <path d="M82.1949 63.9361H79.9365V59.5862H82.1407C83.1525 59.5862 83.6524 60.1611 83.6524 60.8174C83.6524 61.1833 83.5199 61.4679 83.2188 61.6595V61.6711C83.6162 61.7873 83.8511 62.1764 83.8511 62.6468C83.8511 63.4483 83.2489 63.9361 82.1949 63.9361ZM82.0865 60.2947H80.6652L80.7435 60.3702V61.2704L80.6652 61.3459H82.0865C82.6647 61.3459 82.8273 61.102 82.8273 60.8116C82.8273 60.4689 82.6225 60.2889 82.0865 60.2889V60.2947ZM82.1889 62.0544H80.6652L80.7435 62.1299V63.1521L80.6652 63.2276H82.1889C82.8213 63.2276 83.02 63.0127 83.02 62.6468C83.02 62.2809 82.7972 62.0544 82.1829 62.0544H82.1889Z" fill="white"/>
      <path d="M96.2571 59.5803L94.2998 62.1299V63.936H93.4868V62.1299L91.5234 59.5803H92.5352L93.6072 61.0322L93.8782 61.4794H93.8903L94.1552 61.0322L95.2333 59.5803H96.239H96.2571Z" fill="white"/>
      <path d="M118.197 63.9419H117.39V61.253H117.378L117.191 61.7176L115.854 64H115.83L114.493 61.7176L114.306 61.2646H114.294V63.9478H113.487V59.592H114.162L115.619 62.1067L115.842 62.6352H115.854L116.065 62.1067L117.54 59.592H118.191V63.9478L118.197 63.9419Z" fill="white"/>
      <path d="M131.193 63.9361H130.308L129.838 62.972L129.88 62.9023H127.543L127.586 62.972L127.116 63.9361H126.23L128.338 59.5862H129.085L131.193 63.9361ZM129.507 62.188L129.428 62.1183L128.838 60.8987L128.73 60.5386H128.718L128.591 60.8987L128.001 62.1183L127.923 62.188H129.513H129.507Z" fill="white"/>
      <path d="M143.22 63.9361H142.413V62.188L142.491 62.1125H139.968L140.046 62.188V63.9361H139.239V59.5862H140.046V61.3343L139.968 61.4098H142.491L142.413 61.3343V59.5862H143.22V63.9361Z" fill="white"/>
      <path d="M153.561 63.9884C152.814 63.9884 152.043 63.7735 151.459 63.2218L152.037 62.67C152.591 63.1347 153.001 63.2798 153.537 63.2798C154.362 63.2798 154.675 63.0011 154.675 62.7281C154.675 62.5423 154.494 62.31 153.904 62.1706C153.802 62.1416 153.169 61.9906 153.067 61.9673C152.254 61.7582 151.597 61.4737 151.597 60.771C151.597 60.1089 152.23 59.5339 153.501 59.5339C154.259 59.5339 154.886 59.7779 155.392 60.2657L154.813 60.8232C154.47 60.4399 154.061 60.2425 153.513 60.2425C152.682 60.2425 152.429 60.5328 152.429 60.7477C152.429 60.951 152.633 61.0904 153.422 61.2878C153.519 61.3169 154.043 61.4446 154.145 61.4679C155.072 61.6886 155.506 62.1416 155.506 62.6933C155.506 63.3612 154.946 63.9884 153.567 63.9884H153.561Z" fill="white"/>
      <path d="M168.274 63.9361H167.389L166.919 62.972L166.961 62.9023H164.624L164.667 62.972L164.197 63.9361H163.312L165.419 59.5862H166.166L168.274 63.9361ZM166.588 62.188L166.509 62.1183L165.919 60.8987L165.811 60.5386H165.799L165.672 60.8987L165.082 62.1183L165.004 62.188H166.594H166.588Z" fill="white"/>
      </svg>
    </div>
  </div>
</template>

<script setup>
import { ref, provide, onUpdated, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import '@/scss/app.scss'

const showFooter = ref(true);
const showCompanies = ref(true);

provide('showFooter', showFooter);
provide('showCompanies', showCompanies);
</script>