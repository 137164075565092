<template>
  <transition name="slide-fade" mode="out-in">
    <slot />

  </transition>

</template>

<script >
import DefaultFooter from '../../components/Footer.vue'
import DefaultNav from '../../components/Nav.vue'

export default {
  components: {
    DefaultNav,
    DefaultFooter
  }
}
</script>
